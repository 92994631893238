import { useCategoriesStore } from "./categoriesStore";
import { useAPI } from "~/uses/useMyFetch";
import { useMemoize } from "@vueuse/core";
import { Product } from "~/models/product.model";

//To check if array of options has different values
const isHasDiffs = (options) => {
  if (!options) return false;

  const result = new Set();

  for (const option of options) {
    result.add(option.name.ru);
  }

  return result.size > 1;
};

export const useComparisonStore = defineStore("comparisonStore", {
  state: () => {
    return {
      products: [],
      currentCategoryProducts: [],
      currentCategory: null,
      isShownSpecificationsDifference: false,
      isFetching: false,
      memoizedFetch: null,
    };
  },
  actions: {
    async fetch() {
      if (null === this.memoizedFetch) {
        this.memoizedFetch = useMemoize(this.forceFetch);
      }

      return this.memoizedFetch();
    },
    async forceFetch() {
      const categoriesStore = useCategoriesStore();
      const { getTopParentCategory: topParent, getCategoryById } =
        storeToRefs(categoriesStore);
      this.isFetching = true;

      const getTopParentCategory = (id) =>
        topParent.value(getCategoryById.value(id));

      await useAPI("/catalog/product/list/comparison", {
        params: {
          "fields[]": "specifications",
        },
      })
        .then((data) => {
          if (data.length > 0) {
            this.products = data.map(({ product }) => {
              return {
                product: new Product(product),
                parentCategory: getTopParentCategory(product.categories[0].id),
              };
            });

            this.currentCategory = this.products[0].parentCategory;
            this.currentCategoryProducts = this.products.filter(
              ({ parentCategory }) =>
                parentCategory.id === this.currentCategory.id,
            );
          }
        })
        .finally(() => {
          this.isFetching = false;
        });
    },
    async addItem(product) {
      await useAPI("/catalog/product/comparison/add", {
        method: "POST",
        body: {
          productId: product.id,
        },
      });

      await this.forceFetch();
    },
    async removeItem(product) {
      await useAPI("/catalog/product/comparison/remove", {
        method: "POST",
        body: {
          productId: product.id,
        },
      });

      this.products = this.products.filter(
        (comparisonProduct) => comparisonProduct.product.id !== product.id,
      );

      this.currentCategoryProducts = this.products.filter(
        ({ parentCategory }) => parentCategory.id === this.currentCategory.id,
      );

      if (
        0 === this.currentCategoryProducts.length &&
        0 !== this.products.length
      ) {
        this.setCurrentCategory(this.getCategories[0]);
      }

      await useAPI("/catalog/product/list/comparison", {
        params: { "fields[]": "specifications" },
      });
    },

    setCurrentCategory(category) {
      this.currentCategory = category;
      this.currentCategoryProducts = this.products.filter(
        ({ parentCategory }) => parentCategory.id === this.currentCategory.id,
      );
    },

    toggleShowDiff() {
      this.isShownSpecificationsDifference =
        !this.isShownSpecificationsDifference;
    },
  },
  getters: {
    getProducts: (state) => state.products,
    getIsFetching: (state) => state.isFetching,
    getProductsInCategory: (state) => state.currentCategoryProducts,
    getCurrentCategory: (state) => state.currentCategory,
    getIsProductInList() {
      return (id) =>
        this.products.some(
          (comparisonProduct) => comparisonProduct.product.id === id,
        );
    },
    // getCategories() {
    //   const map = []
    //
    //   this.products.forEach((product) => {
    //     map[product.parentCategory.id] = product.parentCategory
    //   })
    //
    //   return Object.values(map)
    // },
    getCategories() {
      const result = [];

      this.products.forEach((comparisonProduct) => {
        const isAdded = result.find(
          (el) => el.id === comparisonProduct.parentCategory.id,
        );

        if (!isAdded) {
          result.push({ ...comparisonProduct.parentCategory, qty: 1 });
        } else {
          result.find(
            (el) => el.id === comparisonProduct.parentCategory.id,
          ).qty += 1;
        }
      });

      return result;
    },
    getSpecList: (state) => {
      const result = [];

      //Initial setup of result, get specs list
      state.currentCategoryProducts.forEach((categoryProduct) => {
        categoryProduct.product.specifications.forEach((spec) => {
          const isAdded = result.find((el) => el.id === spec.id);
          if (!isAdded) {
            result.push({
              id: spec.id,
              name: spec.name,
              option: [],
              hasDiffs: false,
            });
          }
        });
      });

      //Fill spec list with option
      result.forEach((el) => {
        state.currentCategoryProducts.forEach((categoryProduct) => {
          const opts = categoryProduct.product.specifications.find(
            (element) => {
              return element.id === el.id;
            },
          );

          if (opts) {
            el.option.push({ name: opts.option.value });
          } else {
            el.option.push({ name: { ru: "", uk: "" } });
          }
        });
      });

      //Check if options has different values in spec list
      result.forEach((el) => {
        el.hasDiffs = isHasDiffs(el.option);
      });

      return result;
    },
    getShowDiff: (state) => state.isShownSpecificationsDifference,
  },
});
